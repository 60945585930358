import { z } from 'zod'

const emailSchema = z
  .string()
  .email({ message: 'errors.email_is_invalid' })
  .min(8, { message: 'errors.email_is_too_short' })
  .max(255, { message: 'errors.email_is_too_long' })

export const joinWaitlistSchema = z.object({
  reason: z.literal('joinWaitlist'),
  email: emailSchema,
})

export const contactMessageSchema = z.object({
  reason: z.literal('message'),
  email: emailSchema,
  message: z
    .string()
    .min(10)
    .max(1024 * 10),
})
